var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      staticClass: "relationProjectReference",
      attrs: { title: _vm.editTitle, "mask-closable": false },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "Form",
        {
          ref: "relationProjectReferenceForm",
          attrs: {
            model: _vm.relationProjectReferenceForm,
            rules: _vm.relationProjectReferenceFormRule,
            "label-width": 100,
          },
        },
        [
          _c(
            "FormItem",
            { attrs: { label: "适合性别", prop: "allowSex" } },
            [
              _c(
                "Select",
                {
                  attrs: {
                    disabled: _vm.disabled,
                    placeholder: "请选择",
                    clearable: "",
                  },
                  model: {
                    value: _vm.relationProjectReferenceForm.allowSex,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.relationProjectReferenceForm,
                        "allowSex",
                        $$v
                      )
                    },
                    expression: "relationProjectReferenceForm.allowSex",
                  },
                },
                _vm._l(_vm.allowSexPriority, function (item, i) {
                  return _c(
                    "Option",
                    { key: item.value, attrs: { value: item.value } },
                    [_vm._v(_vm._s(item.title))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "年龄最低值", prop: "minAge" } },
            [
              _c("InputNumber", {
                attrs: {
                  disabled: _vm.disabled,
                  placeholder: "请输入年龄最低值",
                },
                model: {
                  value: _vm.relationProjectReferenceForm.minAge,
                  callback: function ($$v) {
                    _vm.$set(_vm.relationProjectReferenceForm, "minAge", $$v)
                  },
                  expression: "relationProjectReferenceForm.minAge",
                },
              }),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "年龄最高值", prop: "maxAge" } },
            [
              _c("InputNumber", {
                attrs: {
                  disabled: _vm.disabled,
                  placeholder: "请输入年龄最高值",
                },
                model: {
                  value: _vm.relationProjectReferenceForm.maxAge,
                  callback: function ($$v) {
                    _vm.$set(_vm.relationProjectReferenceForm, "maxAge", $$v)
                  },
                  expression: "relationProjectReferenceForm.maxAge",
                },
              }),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "健康参考值", prop: "healthyValue" } },
            [
              _c(
                "Row",
                [
                  _c(
                    "Col",
                    { attrs: { span: "9" } },
                    [
                      _c(
                        "Input",
                        {
                          attrs: {
                            disabled: _vm.disabled,
                            type: "number",
                            placeholder: "请输入最小值",
                          },
                          on: { input: _vm.healthyMinChange },
                          model: {
                            value: _vm.relationProjectReferenceForm.healthyMin,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.relationProjectReferenceForm,
                                "healthyMin",
                                $$v
                              )
                            },
                            expression:
                              "relationProjectReferenceForm.healthyMin",
                          },
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "prepend" }, slot: "prepend" },
                            [_vm._v("最小值")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("Col", { attrs: { span: "1" } }, [
                    _c(
                      "div",
                      {
                        staticStyle: { "text-align": "center", width: "100%" },
                      },
                      [_vm._v(" ~ ")]
                    ),
                  ]),
                  _c(
                    "Col",
                    { attrs: { span: "9" } },
                    [
                      _c(
                        "Input",
                        {
                          attrs: {
                            disabled: _vm.disabled,
                            type: "number",
                            placeholder: "请输入最大值",
                          },
                          on: { input: _vm.healthyMaxChange },
                          model: {
                            value: _vm.relationProjectReferenceForm.healthyMax,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.relationProjectReferenceForm,
                                "healthyMax",
                                $$v
                              )
                            },
                            expression:
                              "relationProjectReferenceForm.healthyMax",
                          },
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "prepend" }, slot: "prepend" },
                            [_vm._v("最大值")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("Col", { attrs: { span: "1" } }, [
                    _c(
                      "div",
                      {
                        staticStyle: { "text-align": "center", width: "100%" },
                      },
                      [_vm._v(" 或 ")]
                    ),
                  ]),
                  _c(
                    "Col",
                    { attrs: { span: "4" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          placeholder: "请输入健康参考值",
                        },
                        model: {
                          value: _vm.relationProjectReferenceForm.healthyValue,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.relationProjectReferenceForm,
                              "healthyValue",
                              $$v
                            )
                          },
                          expression:
                            "relationProjectReferenceForm.healthyValue",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "职业参考值", prop: "occupationValue" } },
            [
              _c(
                "Row",
                [
                  _c(
                    "Col",
                    { attrs: { span: "9" } },
                    [
                      _c(
                        "Input",
                        {
                          attrs: {
                            disabled: _vm.disabled,
                            type: "number",
                            placeholder: "请输入最小值",
                          },
                          on: { input: _vm.occupationMinChange },
                          model: {
                            value:
                              _vm.relationProjectReferenceForm.occupationMin,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.relationProjectReferenceForm,
                                "occupationMin",
                                $$v
                              )
                            },
                            expression:
                              "relationProjectReferenceForm.occupationMin",
                          },
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "prepend" }, slot: "prepend" },
                            [_vm._v("最小值")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("Col", { attrs: { span: "1" } }, [
                    _c(
                      "div",
                      {
                        staticStyle: { "text-align": "center", width: "100%" },
                      },
                      [_vm._v(" ~ ")]
                    ),
                  ]),
                  _c(
                    "Col",
                    { attrs: { span: "9" } },
                    [
                      _c(
                        "Input",
                        {
                          attrs: {
                            disabled: _vm.disabled,
                            type: "number",
                            placeholder: "请输入最大值",
                          },
                          on: { input: _vm.occupationMaxChange },
                          model: {
                            value:
                              _vm.relationProjectReferenceForm.occupationMax,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.relationProjectReferenceForm,
                                "occupationMax",
                                $$v
                              )
                            },
                            expression:
                              "relationProjectReferenceForm.occupationMax",
                          },
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "prepend" }, slot: "prepend" },
                            [_vm._v("最大值")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("Col", { attrs: { span: "1" } }, [
                    _c(
                      "div",
                      {
                        staticStyle: { "text-align": "center", width: "100%" },
                      },
                      [_vm._v(" 或 ")]
                    ),
                  ]),
                  _c(
                    "Col",
                    { attrs: { span: "4" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          placeholder: "请输入职业参考值",
                        },
                        model: {
                          value:
                            _vm.relationProjectReferenceForm.occupationValue,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.relationProjectReferenceForm,
                              "occupationValue",
                              $$v
                            )
                          },
                          expression:
                            "relationProjectReferenceForm.occupationValue",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "Button",
            {
              attrs: { type: "text" },
              on: {
                click: function ($event) {
                  _vm.show = false
                },
              },
            },
            [_vm._v("取消")]
          ),
          !_vm.disabled
            ? _c(
                "Button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.handelSubmit },
                },
                [_vm._v("提交")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }